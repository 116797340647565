/* eslint-disable unicorn/no-thenable */
import { useEffect, useReducer } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import {
    Button,
    ButtonColor,
    ButtonType,
    HelperText,
    ValidationStates,
    Wysiwyg,
} from '@jsmdg/yoshi';
import {
    NewsletterButtonState,
    NewsletterReducerActionType,
    Salutation,
} from '../../../../shared/enums/newsletter';
import { newsletterReducer } from '../../../reducers/newsletterReducer';
import { newsletterPersonalize } from '../../../services/newsletterSubscribe';
import { FormikCheckbox, FormikInput, FormikSelect, SelectRender } from '../../Formik';
import { SuccessMessage } from '../../SuccessMessage';
import { newsletterConfig } from '../constants';
import { messages as SubscribeMessages } from '../Subscribe/i18n';
import styles from '../Subscribe/NewsletterSubscribe.module.scss';

const messages = defineMessages({
    validationFieldRequired: {
        defaultMessage: 'Dieses Feld ist ein Pflichtfeld',
    },
    validationChooseOption: {
        defaultMessage: 'Bitte wähle eine Option',
    },
    validationIncompleteBirthday: {
        defaultMessage: 'Bitte vervollständigen',
    },
    labelCustomerType: {
        defaultMessage: 'Ich suche:',
    },
    infoRequiredFields: {
        defaultMessage: '* Erforderlich',
    },
    errorTechnical: {
        defaultMessage: 'Aus technischen Gründen ist die Anmeldung aktuell leider nicht verfügbar.',
    },
    gift: {
        defaultMessage: 'Erlebnisse zum Verschenken',
    },
    forme: {
        defaultMessage: 'Erlebnisse für mich selbst',
    },
    birthday: {
        defaultMessage: 'Geburtstag',
    },
    birthmonth: {
        defaultMessage: 'Geburtsmonat',
    },
    birthyear: {
        defaultMessage: 'Geburtsjahr',
    },
});

type NewsletterSubmitProps = {
    userInterestGift: boolean;
    userInterestExperience: boolean;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    birthDay: string;
    birthMonth: string;
    birthYear: string;
};

type NewsletterPersonalizeProps = {
    readonly predictedUserId: string;
    readonly formHeading: string;
    readonly formButtonLabel: string;
    readonly formAdditionalText: string;
    readonly formSuccessText: string;
    readonly withNameFields?: boolean;
};

const NewsletterPersonalize = ({
    formAdditionalText,
    formButtonLabel,
    formHeading,
    formSuccessText,
    predictedUserId,
    withNameFields = true,
}: NewsletterPersonalizeProps): JSX.Element => {
    const intl = useIntl();

    const [{ buttonState, confirmationContent, errorText, success }, dispatch] = useReducer(
        newsletterReducer,
        newsletterConfig.initialState,
    );

    const days = Array.from({ length: 31 }, (_, index) => {
        const day = 1 + index;
        return { label: `${day}`, value: `${day}` };
    });

    const date = new Date();
    const currentYear = date.getFullYear();
    date.setDate(1);
    const months = Array.from({ length: 12 }, (_, index) => {
        const month = 1 + index;
        date.setMonth(month - 1);
        return {
            label: date.toLocaleString(undefined, { month: 'long' }),
            value: `${month}`,
        };
    });

    const years = Array.from({ length: 100 }, (_, index) => {
        const year = currentYear - index;
        return { label: `${year}`, value: `${year}` };
    });

    const initialValues = {
        userInterestGift: false,
        userInterestExperience: false,
        salutation: undefined,
        firstName: undefined,
        lastName: undefined,
        birthDay: '',
        birthMonth: '',
        birthYear: '',
    };

    const fieldIsRequired = Yup.string().required(
        intl.formatMessage(messages.validationFieldRequired),
    );

    const incompleteBirthday = Yup.string().required(
        intl.formatMessage(messages.validationIncompleteBirthday),
    );

    const defaultShape = {
        birthDay: Yup.string().when(['birthMonth', 'birthYear'], {
            is: (birthMonth: string, birthYear: string) => birthMonth || birthYear,
            then: incompleteBirthday,
        }),
        birthMonth: Yup.string().when(['birthDay', 'birthYear'], {
            is: (birthDay: string, birthYear: string) => birthDay || birthYear,
            then: incompleteBirthday,
        }),
        birthYear: Yup.string().when(['birthDay', 'birthMonth'], {
            is: (birthDay: string, birthMonth: string) => birthDay || birthMonth,
            then: incompleteBirthday,
        }),
    };

    const validationSchema = Yup.object().shape(
        withNameFields
            ? {
                  ...defaultShape,
                  salutation: Yup.string()
                      .oneOf(Object.values(Salutation))
                      .required(intl.formatMessage(messages.validationChooseOption)),
                  firstName: fieldIsRequired,
                  lastName: fieldIsRequired,
              }
            : defaultShape,
        [
            ['birthMonth', 'birthYear'],
            ['birthDay', 'birthMonth'],
            ['birthDay', 'birthYear'],
        ],
    );

    const onSubmit = async ({
        birthDay,
        birthMonth,
        birthYear,
        firstName,
        lastName,
        salutation,
        userInterestExperience,
        userInterestGift,
    }: NewsletterSubmitProps): Promise<void> => {
        dispatch({ type: NewsletterReducerActionType.Loading });

        try {
            await newsletterPersonalize({
                predictedUserId,
                salutation,
                firstName,
                lastName,
                birthDay,
                birthMonth,
                birthYear,
                userInterestGift,
                userInterestExperience,
            });
            dispatch({
                type: NewsletterReducerActionType.Success,
                payload: formSuccessText,
            });
        } catch {
            dispatch({
                type: NewsletterReducerActionType.Error,
                payload: intl.formatMessage(messages.errorTechnical),
            });
        }
    };

    useEffect(() => {
        const trackPageLoad = async () => {
            await window.yieldToMainThread();
            trackAnalyticsEvent({
                eventData: {
                    eventName: GA4EventName.SubscribeNewsletterDoi,
                    feature_category: GA4FeatureCategory.Newsletter,
                },
            });
        };

        trackPageLoad();
    }, []);

    return success ? (
        <SuccessMessage content={confirmationContent} />
    ) : (
        <>
            <Wysiwyg content={formHeading} />
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <p className="theme-text-body-highlight d-block g-col-sm mr-3x">
                        {intl.formatMessage(messages.labelCustomerType)}
                    </p>
                    <FormikCheckbox
                        name="userInterestGift"
                        className="theme-text-body-text my-1x"
                        label={intl.formatMessage(messages.gift)}
                    />
                    <FormikCheckbox
                        name="userInterestExperience"
                        className="theme-text-body-text mb-3x"
                        label={intl.formatMessage(messages.forme)}
                    />
                    <div className={`grid ${styles.gridMaxContent}`}>
                        {withNameFields && (
                            <div className="mb-3x d-flex gap-3x gap-sm-1x flex-column flex-sm-row">
                                <FormikSelect
                                    label={`${intl.formatMessage(SubscribeMessages.salutation)}*`}
                                    name="salutation"
                                    options={[
                                        {
                                            label: intl.formatMessage(SubscribeMessages.man),
                                            value: Salutation.Mr,
                                        },
                                        {
                                            label: intl.formatMessage(SubscribeMessages.woman),
                                            value: Salutation.Ms,
                                        },
                                    ]}
                                    as={SelectRender.YoshiDropdown}
                                />
                                <div className="d-flex gap-3x gap-sm-1x flex-column flex-sm-row">
                                    <div>
                                        <FormikInput
                                            placeholder={intl.formatMessage(
                                                SubscribeMessages.firstname,
                                            )}
                                            name="firstName"
                                            hasRequiredMarker
                                        />
                                    </div>
                                    <div>
                                        <FormikInput
                                            placeholder={intl.formatMessage(
                                                SubscribeMessages.lastname,
                                            )}
                                            name="lastName"
                                            hasRequiredMarker
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div
                            className={classNames(
                                'd-flex gap-1x m-0 mb-2x mr-1-5x-4',
                                withNameFields ? null : styles.input,
                            )}
                        >
                            <FormikSelect
                                label={intl.formatMessage(messages.birthday)}
                                name="birthDay"
                                options={days}
                                id="birthDaySelect"
                            />
                            <FormikSelect
                                label={intl.formatMessage(messages.birthmonth)}
                                name="birthMonth"
                                options={months}
                                id="birthMonthSelect"
                            />
                            <FormikSelect
                                label={intl.formatMessage(messages.birthyear)}
                                name="birthYear"
                                options={years}
                                id="birthYearSelect"
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                        <div className="d-flex flex-column">
                            {errorText && (
                                <HelperText
                                    className="mb-1x"
                                    validationState={ValidationStates.Error}
                                >
                                    {errorText}
                                </HelperText>
                            )}
                            <Button
                                className="flex-grow-1 flex-sm-grow-0 mt-1x mb-4x mb-sm-5x"
                                type={ButtonType.Submit}
                                disabled={buttonState === NewsletterButtonState.Loading}
                                color={ButtonColor.Complementary}
                            >
                                {formButtonLabel}
                            </Button>
                        </div>
                        <p className="mb-1x">{intl.formatMessage(messages.infoRequiredFields)}</p>
                    </div>
                    <Wysiwyg content={formAdditionalText} />
                </Form>
            </Formik>
        </>
    );
};

export { NewsletterPersonalize };
