import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { HelperText, ValidationStates, Wysiwyg } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../../shared/types/fragmentContext';
import { newsletterOptIn } from '../../../services/newsletterSubscribe';
import { NewsletterPersonalize } from '../Personalize/NewsletterPersonalize';

const STATES = {
    ERROR: 'error',
    SUCCESS: 'success',
    PENDING: 'pending',
};

type NewsletterOptInProps = {
    readonly content: string;
    readonly predictedUserId: string;
    readonly formHeading: string;
    readonly formButtonLabel: string;
    readonly formAdditionalText: string;
    readonly formSuccessText: string;
};

const NewsletterOptIn = ({
    content,
    formAdditionalText,
    formButtonLabel,
    formHeading,
    formSuccessText,
    predictedUserId,
}: NewsletterOptInProps): JSX.Element | null => {
    const [state, setState] = useState(STATES.PENDING);
    const { isMydays } = useFragmentContext<FragmentContext>();

    useEffect(() => {
        if (!predictedUserId) {
            setState(STATES.ERROR);
            return;
        }

        // we need this check so that we can test the form using `npm run dev`
        if (process.env.NODE_ENV !== 'development')
            (async () => {
                try {
                    await newsletterOptIn({ predictedUserId });
                    setState(STATES.SUCCESS);
                } catch {
                    setState(STATES.ERROR);
                }
            })();
        else setState(STATES.SUCCESS);
    }, [predictedUserId]);

    switch (state) {
        case STATES.SUCCESS:
            return (
                <>
                    <Wysiwyg content={content} />
                    <NewsletterPersonalize
                        predictedUserId={predictedUserId}
                        formHeading={formHeading}
                        formButtonLabel={formButtonLabel}
                        formAdditionalText={formAdditionalText}
                        formSuccessText={formSuccessText}
                        withNameFields={!isMydays}
                    />
                </>
            );
        case STATES.ERROR:
            return (
                <HelperText validationState={ValidationStates.Error}>
                    <FormattedMessage defaultMessage="Aus technischen Gründen ist die Bestätigung zur Newsletter Anmeldung aktuell leider nicht verfügbar." />
                </HelperText>
            );
        case STATES.PENDING:
        default:
            return null;
    }
};

// @loadable/component supports only default export for now
// eslint-disable-next-line import/no-default-export
export default NewsletterOptIn;
